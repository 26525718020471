// body{
//     background-color: aqua;
// }

// googlechaterror
div#google-visualization-errors-all-13 {
    display: none !important;
}
div#google-visualization-errors-4 {
    display: none !important;
}

.ant-statistic-title {
    color: rgba(0, 0, 0, 0.712);
}


//SEMANTIC BUTTONS
.ui.red.button, .ui.red.buttons .button {
    background-color: #fd2020;
}
.ui.primary.button, .ui.primary.buttons .button {
    background-color: #1890ff;
}
.ui.button{
    border-radius: 0px;
}


//SEMANTIC UI TABLE
.ui.table {
    width: 100%;
    background: #fff;
    margin: 1em 0;
    border: none !important;
    box-shadow: none;
    border-radius: 0px;
    /* text-align: left; */
    color: rgba(0,0,0,.87);
    border-collapse: separate;
    border-spacing: 0;
}



//STATISTIC
.ui.horizontal.statistic {
    background-color: #1890ff;
    padding: 11px 17px;
}
.ui.statistic>.label, .ui.statistics .statistic>.label {
    color: white;
}
.ui.statistic>.value, .ui.statistics .statistic>.value {
    // font-weight: 700;
    color: white;
}


//VERSION PAGE
.version{
    p{
        line-height: 7px;
    }
}




.text-danger{
    color: red !important;
}

#components-layout-demo-side .logo {
height: 32px;
margin: 16px;
background: rgba(255, 255, 255, 0.3);
}
  
.site-layout .site-layout-background {
background: #fff;
}
.ant-layout-header {
    background: #001529 !important;
}
.logo{
    margin-bottom: 60px;
}


.mycb1{
float: right !important;
margin-bottom: 10px !important;
}


.ddx1:after { content: ", "; }
.ddx1:last-child:after { content: ""; }


.mylogo{
    position: absolute;
left: -3px;
height: 57px;
margin-top: -2px;
padding: 13px 14px;
}


//TOOGLE SLIDER
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
    background-color: #4caf50!important;
}